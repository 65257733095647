import React, { useEffect, useState } from 'react'

export const Header = () => {
  const [isMobileNavActive, setMobileNavActive] = useState(false);

  const toggleMobileNav = () => {
    setMobileNavActive(!isMobileNavActive);
  };

  const closeMobileNav = () => {
    setMobileNavActive(false);
  };

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 990;
      if (!isMobile && isMobileNavActive) {
        setMobileNavActive(false);
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobileNavActive]);

  return (
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center">
        <h1 className="logo me-auto"><a href="/">Synctegrate Tech</a></h1>
        <a href="/" className="logo me-auto">
          <img src="assets/img/logo.png" alt="" className="img-fluid" />
        </a>
        <nav id="navbar" className={`navbar ${isMobileNavActive ? 'active' : ''}`}>
          <ul>
            <li><a className="nav-link scrollto active" href="#hero" onClick={closeMobileNav}>Home</a></li>
            <li><a className="nav-link scrollto" href="#about" onClick={closeMobileNav}>Nosotros</a></li>
            <li><a className="nav-link scrollto" href="#services" onClick={closeMobileNav}>Servicios</a></li>
            <li><a className="nav-link scrollto" href="#team" onClick={closeMobileNav}>Equipo</a></li>
            <li><a className="nav-link scrollto" href="#pricing" onClick={closeMobileNav}>Planes</a></li>
            <li><a className="nav-link scrollto" href="#faq" onClick={closeMobileNav}>Preguntas frequentes</a></li>
            <li><a className="nav-link scrollto" href="#contact" onClick={closeMobileNav}>Contacto</a></li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle" onClick={toggleMobileNav}></i>
          <button className="mobile-nav-close" onClick={closeMobileNav} style={{ zIndex: 1000 }}><i className="bi bi-x"></i></button>
        </nav>
      </div>
    </header>
  );
}