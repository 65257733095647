import React from 'react'
import team1 from '../../assets/img/team/ceo.jpg';
import team2 from '../../assets/img/team/cto.jpg';
import team3 from '../../assets/img/team/team-3.jpg';
import team4 from '../../assets/img/team/team-4.jpg';
import { TeamCard } from './Team/TeamCard';

export const Team = () => {
  return (
    <section id="team" class="team section-bg">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>Equipo</h2>
        <p>
          Contamos con un equipo dedicado y altamente capacitado que comparte la pasión por la excelencia en la resolución de problemas informáticos. Nuestros profesionales están comprometidos con la entrega de soluciones innovadoras y personalizadas para cada cliente. Trabajamos de la mano para asegurarnos de que tu experiencia con nosotros sea fluida, eficiente y satisfactoria.
        </p>
      </div>

      <div class="row">

        <TeamCard img={team1} name={'José Garay'} title={'CEO'} linkedin={'https://www.linkedin.com/in/jos%C3%A9-rafael-garay-maldonado-031445a1/'}
        description={'Líder visionario en el mundo empresarial. Su dedicación a la innovación, la excelencia y la orientación estratégica guía a nuestro equipo hacia el éxito. José impulsa nuestra misión de ofrecer soluciones excepcionales y liderar en la industria con integridad y visión a largo plazo'}/>
        <TeamCard img={team2} name={'Sebastián Huaitro'} title={'CTO'} linkedin={'https://www.linkedin.com/in/sebasti%C3%A1n-alejandro-huaitro-maldonado-23b778198/'}
        description={'Nuestro experto en informática. Su enfoque colaborativo y habilidades técnicas destacadas son un motor para el equipo, llevándonos a la vanguardia de las soluciones tecnológicas.'}/>

        {/* <div class="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
          <div class="member d-flex align-items-start">
            <div class="pic"><img src={team1} class="img-fluid" alt=""/></div>
            <div class="member-info">
              <h4>Walter White</h4>
              <span>Chief Executive Officer</span>
              <p>Explicabo voluptatem mollitia et repellat qui dolorum quasi</p>
              <div class="social">
                <a href=""><i class="ri-twitter-fill"></i></a>
                <a href=""><i class="ri-facebook-fill"></i></a>
                <a href=""><i class="ri-instagram-fill"></i></a>
                <a href=""> <i class="ri-linkedin-box-fill"></i> </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="200">
          <div class="member d-flex align-items-start">
            <div class="pic"><img src={team2} class="img-fluid" alt=""/></div>
            <div class="member-info">
              <h4>Sarah Jhonson</h4>
              <span>Product Manager</span>
              <p>Aut maiores voluptates amet et quis praesentium qui senda para</p>
              <div class="social">
                <a href=""><i class="ri-twitter-fill"></i></a>
                <a href=""><i class="ri-facebook-fill"></i></a>
                <a href=""><i class="ri-instagram-fill"></i></a>
                <a href=""> <i class="ri-linkedin-box-fill"></i> </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 mt-4" data-aos="zoom-in" data-aos-delay="300">
          <div class="member d-flex align-items-start">
            <div class="pic"><img src={team3} class="img-fluid" alt=""/></div>
            <div class="member-info">
              <h4>William Anderson</h4>
              <span>CTO</span>
              <p>Quisquam facilis cum velit laborum corrupti fuga rerum quia</p>
              <div class="social">
                <a href=""><i class="ri-twitter-fill"></i></a>
                <a href=""><i class="ri-facebook-fill"></i></a>
                <a href=""><i class="ri-instagram-fill"></i></a>
                <a href=""> <i class="ri-linkedin-box-fill"></i> </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 mt-4" data-aos="zoom-in" data-aos-delay="400">
          <div class="member d-flex align-items-start">
            <div class="pic"><img src={team4} class="img-fluid" alt=""/></div>
            <div class="member-info">
              <h4>Amanda Jepson</h4>
              <span>Accountant</span>
              <p>Dolorum tempora officiis odit laborum officiis et et accusamus</p>
              <div class="social">
                <a href=""><i class="ri-twitter-fill"></i></a>
                <a href=""><i class="ri-facebook-fill"></i></a>
                <a href=""><i class="ri-instagram-fill"></i></a>
                <a href=""> <i class="ri-linkedin-box-fill"></i> </a>
              </div>
            </div>
          </div>
        </div> */}

      </div>

    </div>
  </section>
  )
}
