import React from 'react'

export const Services = () => {
  return (
<section id="services" class="services section-bg">
  <div class="container" data-aos="fade-up">
    <div class="section-title">
      <h2>Servicios</h2>
      <p>Ofrecemos soluciones integrales en ERP y desarrollo informático para abordar la subutilización que afecta al 90% de las empresas en Chile. Nuestros servicios incluyen:</p>
    </div>

    <div class="row">
      <div class="col-xl-3 col-md-6 d-flex align-items-stretch mx-auto text-center" data-aos="zoom-in" data-aos-delay="100">
        <div class="icon-box">
          <div class="icon"><i class="bx bxl-dribbble"></i></div>
          <h4><a href="#">Desarrollo Tecnológico</a></h4>
          <p>Seguridad, Soporte integral Servidores, Estaciones de trabajo, Periféricos, Office 365.</p>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0 mx-auto text-center" data-aos="zoom-in" data-aos-delay="200">
        <div class="icon-box">
          <div class="icon"><i class="bx bx-file"></i></div>
          <h4><a href="#">Soporte ERP SOFTLAND</a></h4>
          <p>Diagnóstico o levantamiento de necesidades 
            Parametrización de sistemas SOFTLAND 
            soporte 
            Reporteria a medida.</p>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}
