import React from 'react'

export const AboutUs = () => {
  return (
    <section id="about" class="about">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>Nosotros</h2>
      </div>

      <div class="row content">
        <div class="col-lg-6">
          <p>
            Bienvenido a <strong>SynctegrateTech</strong>, donde nos dedicamos a resolver los desafíos informáticos que tu empresa pueda enfrentar. Con un enfoque centrado en la resolución de problemas y la optimización del soporte, trabajamos incansablemente para ofrecer soluciones eficientes que maximicen el rendimiento de tus sistemas.
          </p>
          <ul>
            <li><i class="ri-check-double-line"></i> Solución de Problemas en Soporte y Mantenimiento de ERP</li>
            <li><i class="ri-check-double-line"></i> Optimización y Superación de Desafíos de Subutilización</li>
            <li><i class="ri-check-double-line"></i> Implementación Eficiente de Soluciones Personalizadas</li>
            <li><i class="ri-check-double-line"></i> Resolución de Problemas Específicos en el Ámbito Empresarial</li>
          </ul>
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0">
          <p>
            Nos comprometemos a resolver problemas de soporte de manera rápida y eficaz. Nuestro equipo experto está dedicado a superar cualquier obstáculo técnico que tu empresa pueda enfrentar.
          </p>
          <p>
            En <strong>SynctegrateTech</strong>, operamos bajo un contrato de servicios diseñado para rentabilizar tu inversión. Nos comprometemos a ofrecer resultados tangibles y a construir relaciones sólidas con nuestros clientes.
          </p>
          <p>
            Descubre cómo <strong>SynctegrateTech</strong> puede transformar tu experiencia informática y ayudarte a alcanzar tus objetivos empresariales. ¡Estamos aquí para superar tus expectativas y hacer que la informática sea una herramienta estratégica para tu éxito!
          </p>
          {/* <a href="#" class="btn-learn-more">Learn More</a> */}
        </div>
      </div>
    </div>
  </section>
  )
}
