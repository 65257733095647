import React from 'react'
import client1 from '../../assets/img/clients/artl.png';
import client2 from '../../assets/img/clients/context.jpg';
import client3 from '../../assets/img/clients/sibo_empresa.png';


export const Clients = () => {
  return (
    <section id="clients" className="clients section-bg">
      <div className="container">
        <div className="row" data-aos="zoom-in">
          <div className="col-lg-2 col-md-4 col-6 mx-auto text-center">
            <img src={client1} className="img-fluid" alt=""/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 mx-auto text-center">
            <img src={client2} className="img-fluid h-75" alt=""/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 mx-auto text-center">
            <img src={client3} className="img-fluid" alt=""/>
          </div>

        </div>
      </div>
    </section>
  )
}
