import React, { useState } from 'react'
import whyuse from '../../assets/img/why-us.png';

export const WhyUs = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const accordionItems = [
    {
      id: 1,
      title: 'Experiencia y Juventud Convincente',
      content:
        'Aunque somos una empresa emergente, nuestro equipo aporta una combinación única de frescura y experiencia en el área. Esta fusión nos permite abordar desafíos con una perspectiva innovadora y eficaz.',
    },
    {
      id: 2,
      title: 'Respaldo de Clientes Satisfechos',
      content:
        'Contamos con amplias referencias que respaldan la calidad y eficacia de nuestros servicios. La satisfacción de nuestros clientes es evidencia de nuestro compromiso con la excelencia y la entrega de resultados excepcionales.',
    },
    {
      id: 3,
      title: 'Especialización en ERP y Adaptabilidad',
      content:
        'Nos especializamos en soluciones ERP, ofreciendo servicios adaptados a las necesidades específicas de cada cliente. Nuestra capacidad para combinar especialización con flexibilidad garantiza soluciones innovadoras y orientadas al éxito en el ámbito de los sistemas de planificación empresarial.',
    },
  ];

  return (
    <section id="why-us" className="why-us section-bg">
    <div className="container-fluid" data-aos="fade-up">

      <div className="row">

        <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

          <div className="content">
            <h3>¿Por que somos los mejores para tú <strong>Negocio</strong>?</h3>
            <p>
              Somos una empresa en crecimiento, respaldada por un equipo multidisciplinario y comprometido que aporta una valiosa experiencia en el área. A pesar de nuestra reciente presencia en el mercado, nos hemos esforzado incansablemente para destacarnos en la industria. Nuestra dedicación se evidencia en el respaldo de amplias referencias que certifican la excelencia de nuestros servicios.
            </p>
          </div>

          <div className="accordion-list">
          <ul className="">
            {accordionItems.map((item, index) => (
              <li key={item.id}>
                <a
                  data-bs-toggle="collapse"
                  className={`collapse ${activeIndex === index ? 'show' : ''}`}
                  data-bs-target={`#accordion-list-${item.id}`}
                  onClick={() => handleToggle(index)}
                  style={{ color: activeIndex === index ? '#47b2e4' : 'black',textDecoration: activeIndex === index ? 'underline': 'none' }}
                >
                  <span>{String(item.id).padStart(2, '0')}</span> {item.title}{' '}
                  <i className={`bx ${activeIndex === index ? 'bx-chevron-up' : 'bx-chevron-down'} icon-show`}></i>
                  <i className={`bx ${activeIndex === index ? 'bx-chevron-up' : 'bx-chevron-down'} icon-close`}></i>
                </a>
                <div
                  id={`accordion-list-${item.id}`}
                  className={`animate__animated collapse ${activeIndex === index ? 'show fade animate__fadeIn' : 'animate__fadeOut'}`} // Agregamos la clase fade
                  data-bs-parent=".accordion-list"
                >
                  <p>{item.content}</p>
                </div>
              </li>
            ))}
          </ul>
          </div>

        </div>

         <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" 
        style={{ backgroundImage: `url(${whyuse})` }}
         data-aos="zoom-in" data-aos-delay="150">&nbsp;</div>
      </div>

    </div>
  </section>
  )
}
