import React, { useState } from 'react'

export const Contact = () => {

  const numberWhatsapp = '+56950253635';

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, email, subject, message } = formData;

    // Construye el mensaje de WhatsApp
    const whatsappMessage = `Nombre: ${name}%0AEmail: ${email}%0ASubject: ${subject}%0AMensaje: ${message}`;

    // URL de WhatsApp API 
    const whatsappAPI = `https://api.whatsapp.com/send?phone=${numberWhatsapp}&text=${whatsappMessage}`;

    window.open(whatsappAPI, '_blank');

    setFormData({
      name: '',
      email: '',
      subject: '',
      message: ''
    })
  };

  return (
    <section id="contact" class="contact">
    <div className="container" data-aos="fade-up">
      <div className="section-title">
        <h2>Contacto</h2>
        <p>Contactanos vía Whatsapp y te contestaremos a la brevedad</p>
      </div>

      <div className="row">
        <div className="col-lg-12 mt-5 mt-lg-0 d-flex align-items-stretch">
          <form
            method="post"
            role="form"
            className="php-email-form"
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="name">Tu Nombre</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  onChange={handleChange}
                  value={formData.name}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="email">Tu Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  onChange={handleChange}
                  value={formData.email}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="subject">Asunto</label>
              <input
                type="text"
                className="form-control"
                name="subject"
                id="subject"
                onChange={handleChange}
                value={formData.subject}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Mensaje</label>
              <textarea
                className="form-control"
                name="message"
                rows="10"
                onChange={handleChange}
                value={formData.message}
                required
              ></textarea>
            </div>
            <div className="my-3">
              <div className="loading">Cargando</div>
              <div className="error-message"></div>
              <div className="sent-message">
                ¡Tu mensaje ha sido enviado. ¡Gracias!
              </div>
            </div>
            <div className="text-center">
              <button type="submit">Enviar Mensaje</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  )
}
