import React, { useEffect } from 'react';
import { AppProvider } from './context/AppContext';
import { Header } from './components/Header';

import './App.css';
import './assets/css/style.css';
// import './assets/vendor/aos/aos.css';
import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './assets/vendor/boxicons/css/boxicons.min.css';
import './assets/vendor/glightbox/css/glightbox.min.css';
import './assets/vendor/remixicon/remixicon.css';
import './assets/vendor/swiper/swiper-bundle.min.css';
import { Hero } from './components/Hero';
import { Home } from './components/Home';
import { Footer } from './components/Footer';

const App = () => {
  useEffect(() => {
    // Función para seleccionar el elemento del encabezado
    const select = (selector) => document.querySelector(selector);

    // Función para agregar o quitar la clase según el desplazamiento
    const headerScrolled = () => {
      const selectHeader = select('#header');
      if (selectHeader) {
        if (window.scrollY > 100) {
          selectHeader.classList.add('header-scrolled');
        } else {
          selectHeader.classList.remove('header-scrolled');
        }
      }
    };

    // Agrega el evento de carga y el evento de desplazamiento
    window.addEventListener('load', headerScrolled);
    window.addEventListener('scroll', headerScrolled);

    // Limpia los eventos cuando el componente se desmonta
    return () => {
      window.removeEventListener('load', headerScrolled);
      window.removeEventListener('scroll', headerScrolled);
    };
  }, []);
  return (
    <AppProvider>
      <Header />
      <Hero />
      <Home />
      <Footer />
    </AppProvider>
  );
};

export default App;
