import React from 'react'
import { Clients } from './home/Clients'
import { AboutUs } from './home/AboutUs'
import { WhyUs } from './home/WhyUs'
import { Services } from './home/Services'
import { Skills } from './home/Skills'
import { CallToAction } from './home/CallToAction'
import { Portfolio } from './home/Portfolio'
import { Team } from './home/Team'
import { Pricing } from './home/Pricing'
import { FrequentlyAsked } from './home/FrequentlyAsked'
import { Contact } from './home/Contact'

export const Home = () => {
  return (
    <main id="main">
        <Clients />
        <AboutUs />
        <WhyUs />
        <Skills />
        <Services />
        <CallToAction />
        {/* <Portfolio /> */}
        <Team />
        {/* <Pricing /> */}
        <FrequentlyAsked />
        <Contact />
    </main>
  )
}
