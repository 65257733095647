import React from 'react'
import skills from '../../assets/img/skills.png';

export const Skills = () => {
  return (
<section id="skills" class="skills">
  <div class="container" data-aos="fade-up">
    <div class="row">
      <div class="col-lg-6 d-flex align-items-center" data-aos="fade-right" data-aos-delay="100">
        <img src={skills} class="img-fluid" alt="" />
      </div>
      <div class="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left" data-aos-delay="100">
        <h3>Soluciones Integrales en ERP y Desarrollo Informático</h3>
        <p class="fst-italic">
          En SyntagrateTech, nos especializamos en abordar la subutilización de los ERP que afecta al 90% de las empresas en Chile. Ofrecemos soluciones integrales para optimizar el uso de los sistemas, brindando una gestión completa desde contabilidad hasta recursos humanos.
        </p>

        <div class="skills-content">
          <ul class="list-unstyled">
            <li>
              <i class="bi bi-check"></i> Compromiso con la excelencia en el servicio.
            </li>
            <li>
              <i class="bi bi-check"></i> Dedicación a la satisfacción del cliente.
            </li>
            <li>
              <i class="bi bi-check"></i> Experiencia sólida en el área.
            </li>
            <li>
              <i class="bi bi-check"></i> Enfoque proactivo y solucionador de problemas.
            </li>
          </ul>
        </div>
        
      </div>
    </div>
  </div>
</section>


  )
}
