import React from 'react'

import imagen from '../assets/img/hero-img.png';

export const Hero = () => {
  return (
    <section id="hero" className="d-flex align-items-center">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
          <h1>Los Mejores buscando soluciones a tus problemas</h1>
          <h2>Profesionales expertos en Integración con Softland y reportes personalizados para potenciar tu empresa.</h2>
          <div className="d-flex justify-content-center justify-content-lg-start">
            <a href="#about" className="btn-get-started scrollto">Comenzemos</a>
            {/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox btn-watch-video">
                <i className="bi bi-play-circle"></i>
                <span>Ver Video</span>
            </a> */}
          </div>
        </div>
        <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
          <img src={imagen} className="img-fluid animated" alt="" />
        </div>
      </div>
    </div>
    </section>
  )
}
