import React, { useState } from 'react'

export const FrequentlyAsked = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      id: 1,
      question: '¿Cuáles son los beneficios de elegir SyntagrateTech para nuestras soluciones informáticas?',
      answer:
        'En SyntagrateTech, nos destacamos por ofrecer soluciones eficientes y personalizadas que se adaptan a las necesidades específicas de cada cliente. Nuestro enfoque centrado en el cliente, experiencia sólida y compromiso con la excelencia garantizan beneficios tangibles para tu empresa.',
    },
    {
      id: 2,
      question: '¿Cómo puedo solicitar una consulta o evaluar mis necesidades informáticas con SyntagrateTech?',
      answer:
        'Puedes solicitar una consulta o evaluar tus necesidades informáticas con SyntagrateTech a través de nuestro formulario de contacto en línea o comunicándote directamente con nuestro equipo. Estamos aquí para ayudarte y brindarte la asesoría necesaria.',
    },
    {
      id: 3,
      question: '¿Cuál es el proceso de implementación de soluciones informáticas en mi empresa?',
      answer:
        'El proceso de implementación varía según la complejidad de las soluciones requeridas. Nuestro equipo se encargará de evaluar tus necesidades, proponer un plan de implementación detallado y brindar el soporte necesario para garantizar una transición sin problemas.',
    },
  ];

  return (
    <section id="faq" className="faq section-bg-2">
      <div className="container" data-aos="fade-up">
      <div class="section-title-2">
        <h2>Preguntas Frecuentes</h2>
        <p>Aquí encontrarás respuestas a algunas preguntas comunes sobre nuestros servicios y empresa.</p>
      </div>

        <div className="faq-list">
          <ul>
            {faqItems.map((item, index) => (
              <li key={item.id} data-aos="fade-up" data-aos-delay={100 * index}>
                <i className="bx bx-help-circle icon-help"></i>{' '}
                <a
                  data-bs-toggle="collapse"
                  className={`collapse ${activeIndex === index ? 'show' : ''}`}
                  data-bs-target={`#faq-list-${item.id}`}
                  onClick={() => handleToggle(index)}
                  style={{ color: activeIndex === index ? '#47b2e4' : 'black',textDecoration: activeIndex === index ? 'underline': 'none' }}
                >
                  {item.question} <i className={`bx ${activeIndex === index ? 'bx-chevron-up' : 'bx-chevron-down'} icon-show`}></i>
                  <i className={`bx ${activeIndex === index ? 'bx-chevron-up' : 'bx-chevron-down'} icon-close`}></i>
                </a>
                <div
                  id={`faq-list-${item.id}`}
                  className={`animate__animated collapse ${activeIndex === index ? 'show fade animate__fadeIn' : 'animate__fadeOut'}`}
                  data-bs-parent=".faq-list"
                >
                  <p>{item.answer}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}
