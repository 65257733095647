import React from 'react'

export const TeamCard = ({img,name,title,description,twitter,facebook,instagram,linkedin}) => {
  return (
    <div class="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
        <div class="member d-flex align-items-start">
        <div class="pic"><img src={img} class="img-fluid" alt=""/></div>
        <div class="member-info">
            <h4>{name}</h4>
            <span>{title}</span>
            <p>{description}</p>
            <div class="social">
                {
                    twitter && 
                    <a href={twitter}><i class="ri-twitter-fill"></i></a>
                }
                {
                    facebook && 
                    <a href={facebook}><i class="ri-facebook-fill"></i></a>
                }
                {
                    instagram && 
                    <a href={instagram}><i class="ri-instagram-fill"></i></a>
                }
                {
                    linkedin && 
                    <a href={linkedin}> <i class="ri-linkedin-box-fill"></i> </a>
                }
            </div>
        </div>
        </div>
    </div>
  )
}
