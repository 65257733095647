import React from 'react'

 export const Footer = () => {
   return (
     <div id="footer">
        <div className="footer-newsletter">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <h4>Confía en nosotros!</h4>
                        <p>Somos expertos en impulsar el crecimiento de tu empresa, garantizando de que cada paso que tomes esté detalladamente planificada en tu área de negocios. Nos destacamos por nuestra dedicación y  excelencia nuestros servicios. Trabajamos incansablemente para perfeccionar nuestras soluciones, siempre con el propósito de mantener a nuestros clientes satisfechos y posicionados en la cima de su sector. Con nosotros, no solo obtienes un servicio, sino también un compromiso continuo con el éxito y la prosperidad de tu negocio.</p>
                        {/* <form action="" method="post">
                        <input type="email" name="email"/><input type="submit" value="Subscribe"/>
                        </form> */}
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-top">
            <div className='container'>
                <div className='row'>
                    <div className="col-lg-3 col-md-6 footer-contact">
                        <h3>Syntegrate Tech</h3>
                        <p>
                            Santiago <br />
                            Chile <br/><br/>
                            <strong>Phone:</strong> +56 950253635<br/>
                            <strong>Email:</strong> syntegratetech@gmail.com<br/>
                        </p>
                    </div>
                    <div className="col-lg-3 col-md-6 footer-links">
                        <h4>Useful Links</h4>
                        <ul>
                            <li><i className="bx bx-chevron-right"></i> <a href="#">Inicio</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a href="#">Nosostros</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a href="#">Servicios</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a href="#">Terminos de servicios</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a href="#">Politica de privacidad</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 footer-links">
                        <h4>Our Services</h4>
                        <ul>
                        <li><i className="bx bx-chevron-right"></i> <a href="#">Resolución de problemas</a></li>
                        <li><i className="bx bx-chevron-right"></i> <a href="#">Imprementación eficiente</a></li>
                        <li><i className="bx bx-chevron-right"></i> <a href="#">Consultoria</a></li>
                        <li><i className="bx bx-chevron-right"></i> <a href="#">Reportería</a></li>
                        <li><i className="bx bx-chevron-right"></i> <a href="#">Soporte</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 footer-links">
                        <h4>Nuestras redes sociales</h4>
                        <p>Nos pueden contactar y ver nuestros servicios en acción en cualquiera de estas plataformas!</p>
                        <div className="social-links mt-3">
                        {/* <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a> */}
                        {/* <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a> */}
                        <a href="https://www.instagram.com/synctegrate/" className="instagram"><i className="bx bxl-instagram"></i></a>
                        <a href="https://www.linkedin.com/in/consultoria-softland-1279882b2/" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container footer-bottom clearfix">
                <div className="copyright">
                    &copy; Copyright <strong><span>SyntegrateTech</span></strong>. All Rights Reserved
                </div>
            </div>
        </div>
   </div>
   )
 }
