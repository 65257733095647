import React from 'react'

export const CallToAction = () => {
  return (
    <section id="cta" class="cta">
    <div class="container" data-aos="zoom-in">

      <div class="row">
        <div class="col-lg-9 text-center text-lg-start">
          <h3>Compromisos y resultados!</h3>
          <p> Descubre cómo SyntagrateTech puede transformar tu experiencia informática y ayudarte a alcanzar tus objetivos empresariales. Estamos aquí para superar tus expectativas y hacer que la informática sea una herramienta estratégica para tu éxito. ¡Permítenos llevar tu empresa al siguiente nivel tecnológico!</p>
        </div>
        <div class="col-lg-3 cta-btn-container text-center">
          <a class="cta-btn align-middle" href="#contact">Contactanos</a>
        </div>
      </div>

    </div>
  </section>
  )
}
